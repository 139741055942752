<template>
  <v-dialog
      v-model="dialogConfirmationInfo"
      width="700"
      persistent
    >
    <v-card>
      <v-card-title class="headline info white--text">
        Confirmación y pickup de la reserva: {{reservation.code}}
      </v-card-title>

      <v-card-text>
         <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
            class="pt-4"
          >
            <!-- v-model="validForm" -->
            <v-menu
              v-if="reservation.transportation"
              ref="menu"
              v-model="menuTimepicker"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="r.pickupTime"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="r.pickupTime"
                  label="Ingrese la hora de pickup"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  :rules="[rules.required]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menuTimepicker"
                v-model="r.pickupTime"
                full-width
                @click:minute="$refs.menu.save(r.pickupTime)"
              ></v-time-picker>
            </v-menu>
            <v-textarea
              v-model="r.observations"
              name="input-7-1"
              label="Observaciones"
              :rules="[rules.required]"
              rows="3"
              :prepend-icon="mdiMessageAlertOutline "
              placeholder="Ingrese las Observaciones"
            ></v-textarea>
            <v-checkbox
              :rules="[rules.required]"
              v-model="r.confirmationInfo"
              :label="`Confirmo que los datos e informacion del cliente son correctos`"
            ></v-checkbox>
          </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="close"
        >
          Cerrar
        </v-btn>
        <v-btn
          :disabled="!validForm"
          color="primary"
          class="mr-4"
          @click="validateForm"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
      
</template>
<script>
  import { mdiMessageAlertOutline  } from "@mdi/js";
  import Reservation from "@/models/Reservation";
  //import { mdiHumanCane  } from '@/assets/icons/mdi-icons-extended.js';
  import {mapState} from 'vuex'
  export default{
    name:"ConfirmationInfoReservation",
    props:['reservation'],
    data() {
      return {
        mdiMessageAlertOutline : mdiMessageAlertOutline ,
        validForm: true,
        reservationModel: new Reservation,
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        //openModal: this.open || false,
        r:{
          pickupTime: null,
          observations: '',
          confirmationInfo: false,
        },
        menuTimepicker: null,
        rules:{
          required: v => !!v || 'El campo es obligatorio',
          /*codeLenght: v => v && v.length == 3 || 'El campo codigo debe contener 3 letras',
          number : v => {
            const pattern = /^[0-9]+([.][0-9]+)?$/
            return pattern.test(v) || 'Numero invalido'
          },
          alpha : v => {
            const pattern = /^[a-zA-Z]+?$/
            return pattern.test(v) || 'El codigo solo deben ser letras'
          },*/
        }
      }
    },
    methods:{
      close() {
        this.$store.dispatch('reservation/setDialogConfirmationInfo', false)
      },
      validateForm() {
        if (this.$refs.form.validate()) {
          let loading = this.$loading.show();
          try {
            this.reservationModel.confirmationInfoAndPickup(this.reservation.id, this.r)
            this.$store.dispatch('reservation/setDialogConfirmationInfo', false)
            this.showSnackbarSuccess('La informacion se guardo correctamente');
            loading.hide();
          } catch (e) {
            loading.hide();
            this.showSnackbarError('Error guardar la informacion');
          }
          /*this.reservationModel.confirmationInfoAndPickup(this.reservation.id, this.r).then(() => {
          })
          .catch( error=> {
            console.log(error);
            loading.hide();
            this.showSnackbarError('Error guardar la informacion');
          })*/
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
    },
    computed: mapState({
      dialogConfirmationInfo:state=>state.reservation.dialogConfirmationInfo
    })
  }
</script>