<template>
  <v-btn
    x-small :color="color"
    :dark="isDark">
    <v-icon v-if="icon" small>{{icon}}</v-icon>
    {{ status }}
  </v-btn>
</template>
<script>
  export default {
    name:'BtnStatus',
    props:['status', 'icon'],
    computed:{
      color() {
        if (this.status == 'confirmed') {
          return 'success'
        }
        if (this.status == 'checkin') {
          return 'info'
        }
        if (this.status == 'cancelled') {
          return 'error'
        }
        if (this.status == 'noshow') {
          return 'warning'
        }
        return ''
      },
      isDark() {
        return this.status == 'confirmed';
      }
    }
  }
</script>