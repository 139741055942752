<template>
  <div style="">
    <portal to="title-page">
      <h1>Listado de reservaciones por dia</h1>
    </portal>
    <v-data-table
      v-model="selected"
      :headers="tableHeaders"
      :items="items"
      item-key="id"
      fixed-header
      :loading="loading"
      class="elevation-1"
    >
      <!-- :search="search" -->
      <!-- show-select -->
      <!-- :single-select="singleSelect" -->
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col cols="12" sm="2" md="3">
              <v-select
                v-model="searchBy"
                :items="['ID', 'Email', 'Fechas']"
                label="Buscar por"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="2" md="3" v-if="searchBy == 'Email'">
              <v-text-field
                v-model="filters.email"
                label="Email"
                placeholder="cliente@domain.com"
                prepend-icon="mdi-email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3" v-if="searchBy == 'ID'">
              <v-text-field
                v-model="filters.code"
                label="ID"
                placeholder="CZ00001"
                prepend-icon="mdi-identifier"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="searchBy == 'Fechas'"
              cols="12"
              sm="4"
              md="2"
            >
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Fecha Inicio"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              v-if="searchBy == 'Fechas'"
              cols="12"
              sm="4"
              md="2"
            >
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    label="Fecha fin"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="4" md="2" v-if="searchBy == 'Fechas'">
              <v-select
                v-model="transportation"
                :items="transportationOptions"
                item-text="text"
                item-value="value"
                label="Transporte"
                return-object
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-btn class="mt-md-3" small @click="searchReservations()"
                elevation="2"
                color="primary"
              ><v-icon>mdi-magnify</v-icon> Buscar</v-btn>
            </v-col>
            <!-- <v-col cols="6" sm="6" md="6">
              <v-btn @click="$router.push({path:'/backend/admin/currencies/create'})" small color="primary"><v-icon>mdi-plus-thick</v-icon> Moneda</v-btn>
            </v-col> -->
          </v-row>
        </div>
        <v-divider></v-divider>
        <!-- <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch> -->
      </template>
      <template v-slot:item.confirmed="{ item }">
        <v-icon 
          v-if="!item.confirmationInfo"
          class=""
          @click.stop="openModalConfirmationInfo(item)"
        >
          {{mdiCheckboxBlankCircleOutline}}
        </v-icon>
        <v-icon 
          v-if="item.confirmationInfo"
          class="green--text"
        >
          {{ mdiCheckCircle }}
        </v-icon>
        <br>
        <template v-if="item.pickupDateTime">
          {{$moment(item.pickupDateTime.seconds * 1000).format('HH:mma')}}
        </template>
      </template>
      <template v-slot:item.code="{ item }">
        {{item.code}}
        <a href="#"><small>{{ item.panel == 'taquilla' ? 'Taquilla' : 'Web'}}</small></a>
      </template>
      <template v-slot:item.tour="{ item }">
        {{ item.tour }}
      </template>
      <template v-slot:item.pax="{ item }">
        <div v-if="item.adults > 0">Adultos: {{item.adults}}</div>
        <div v-if="item.elderlies > 0">A.Mayores: {{item.elderlies}}</div>
        <div v-if="item.children > 0">Niños: {{item.children}}</div>
        <div v-if="item.infants > 0">Infantes: {{item.infants}}</div>
      </template>
      <template v-slot:item.customer="{ item }">
        <div >{{item.customerEmail}}</div>
        <div >{{item.customerName}} {{item.customerLastname}}</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <a :href="`/backend/taquilla/reservations/${item.id}/edit`" style="text-decoration: none" @click="redirectTo(`/backend/taquilla/reservations/${item.id}/edit`, $event)">
          <v-icon 
            class="mr-2"
          >
            <!-- @click="$router.push({path:`/backend/taquilla/reservations/${item.id}/edit`})" -->
            mdi-pencil
          </v-icon>
        </a>
      </template>
      <template v-slot:item.status="{ item }">
        <BtnStatus :icon="mdiPencil" :status="item.status" v-on:click.native="test"/>
      </template>
      <template  v-slot:item.paymentStatus="{ item }">
        <BtnPaymentStatus :icon="mdiPencil" :status="item.paymentStatus" />
      </template>
      <template v-slot:item.total="{ item }">
        ${{item.total}}<small>{{item.currency}}</small>
      </template>
    </v-data-table>
    <ConfirmationInfo v-if="reservation"  :reservation="reservation"/>
  </div>
</template>
<script>
  import Reservation from "@/models/Reservation";
  import ConfirmationInfo from "@/components/reservations/ConfirmationInfo";
  import BtnStatus from "@/components/reservations/BtnStatus";
  import BtnPaymentStatus from "@/components/reservations/BtnPaymentStatus";
  import { /*mdiClose, mdiArrowLeft*/mdiPencil, mdiCheckboxBlankCircleOutline, mdiCheckCircle } from '@mdi/js';
  //import {mapState} from 'vuex'
  
  export default {
    name:'TaquillaReservations',
    components:{ConfirmationInfo, BtnStatus, BtnPaymentStatus},
    data() {
      let date = this.$moment()//.add(1, 'day');
      return {
        mdiPencil:mdiPencil,
        mdiCheckboxBlankCircleOutline:mdiCheckboxBlankCircleOutline,
        mdiCheckCircle:mdiCheckCircle,
        startDate:date.format('YYYY-MM-DD'),
        endDate:date.format('YYYY-MM-DD'),
        reservationModel:new Reservation,
        items:[],
        unsubscribe:null,
        search:'',
        panel:'',
        selected:[],
        panels:[{text:'Todos', value: ''}, {text: 'Web', value: 'website'}, {text: 'Taquilla', value: 'taquilla'}],
        loading:false,
        tableHeaders:[
          {
            text: 'Pickup',
            align: 'center',
            sortable: true,
            value: 'confirmed',
          },
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'code',
            width:"140px"
          },
          {
            text: 'Tour',
            align: 'start',
            sortable: true,
            value: 'tour',
          },
          // {
          //   text: 'Fecha',
          //   align: 'start',
          //   sortable: true,
          //   value: 'pax',
          // },
          {
            text: 'Pax',
            align: 'start',
            sortable: true,
            value: 'pax',
          },
          {
            text: 'Cliente',
            align: 'start',
            sortable: true,
            value: 'customer',
          },
          {
            text: 'Status',
            align: 'start',
            sortable: true,
            value: 'status',
          },
          {
            text: 'Pago',
            align: 'start',
            sortable: true,
            value: 'paymentStatus',
          },
          {
            text: 'Total',
            align: 'center',
            sortable: true,
            value: 'total',
          },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        searchBy: 'ID',
        menu1: false,
        menu2: false,
        dialog: false,
        reservation: null,
        transportation: {text: 'Todos', value: ''},
        transportationOptions: [
          {text: 'Todos', value: ''},
          {text: 'Si', value: 1},
          {text: 'No', value: 0},
        ],
        filters:{
          creationDate: '',
          code: '',
          email: '',
        }
      };
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'Taquilla')
    },
    mounted() {
      //this.searchReservations();
    },
    methods:{
      getFilters() {
        let filters = {}
        if (this.searchBy == 'Fechas') {
          filters.startDate = this.$moment(this.startDate)
          filters.endDate = this.$moment(this.endDate)
          filters.transportation = this.transportation
        } else {
          if (this.filters.code !== '') {
            filters.code = this.filters.code
          }
          if (this.filters.email !== '') {
            filters.email = this.filters.email
          }
        }
        return filters
      },
      searchReservations() {
        if (this.unsubscribe) {
          this.unsubscribe();
        }
        this.loading = true;
        //this.unsubscribe = this.reservationModel.findByDate(this.$moment(this.date)).onSnapshot(snap => {
        this.unsubscribe = this.reservationModel.search(this.getFilters()).onSnapshot(snap => {
          this.items = [];
          snap.forEach(doc => {
            console.log('doc.data().transportation', doc.data().transportation)
            console.log('this.transportation.value', this.transportation.value)

            if (this.transportation.value === 1) {
              if (doc.data().transportation) {
                this.items.push(doc.data());
              }
              console.log(doc.id, 'lalal')
            } else if (this.transportation.value === 0) {
              if (!doc.data().transportation ) {
                this.items.push(doc.data());
              }
              console.log(doc.id, 'xxx')
            } else  {
              console.log(doc.id)
              this.items.push(doc.data());
            }
          })
          this.loading = false;
        })
      },
      openModalConfirmationInfo(reservation) {
        this.reservation = reservation
        this.$store.dispatch('reservation/setDialogConfirmationInfo', true)
      },
      redirectTo(url, event) {
        if (event) {
          event.preventDefault();
        }
        this.$router.push({path:url})
      }
    },
    watch: {
      panel: function() {
        this.searchReservations()
      }
    }
    //computed: mapState(['dialogConfirmationInfo'])
  }
</script>