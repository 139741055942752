<template>
  <v-btn
    x-small
    :color="color"
    :dark="isDark">
    <v-icon v-if="icon" small>{{icon}}</v-icon>
    {{ status }}
  </v-btn>
</template>
<script>
  export default {
    name:'BtnPaymentStatus',
    props:['status', 'icon', 'balance'],
    computed:{
      color() {
        if (this.status == 'confirmed') {
          return 'success'
        }
        if (this.status == 'balance' ) {
          return 'warning'
        }
        return ''
      },
      isDark() {
        return this.status == 'confirmed';
      }
    }
  }
</script>